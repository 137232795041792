import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
	background-color: black;
	color: white;
	font-size: 0.875rem;
	padding: 13px 24px;
	text-align: center;
`;

const Footer: React.FC = () => {
	return (
		<FooterWrapper>
			<div>© 2021 TCM Bank, N.A.</div>
			<div>
				This card is issued by TCM Bank, N.A. Subject to credit approval.
			</div>
			<div>© 2021 Financial site powered by Alkami.</div>
		</FooterWrapper>
	);
};

export default Footer;
