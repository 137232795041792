import * as React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../util/util';

const Wrapper = styled.div`
	padding: 1em;
`;

const ThankyouText = styled.div`
	${props => props.theme.typography.h4}
	margin: auto;
	margin-bottom: 24px;
	max-width: 1100px;
`;
const NewCreditLineText = styled.div`
	color: #34495e;
	${props => props.theme.typography.body1}
	margin-bottom: 12px;
`;

const CliResults: React.FunctionComponent<{
	newCreditLine?: undefined | Boolean | number;
}> = function CliResults({ newCreditLine }) {
	return (
		<Wrapper>
			{newCreditLine ? (
				<>
					<ThankyouText>
						Thank you and congratulations! Your credit line increase request has
						been approved.
					</ThankyouText>
					<br />

					<NewCreditLineText>
						<b>
							New credit line:{' '}
							{formatCurrency(newCreditLine as number, { noFraction: true })}
						</b>
					</NewCreditLineText>
					<NewCreditLineText>
						Your new credit line will be available within 3-5 business days.
					</NewCreditLineText>
					<br />
					<NewCreditLineText>
						Questions? Call 1-800-883-0131.
					</NewCreditLineText>
				</>
			) : (
				<ThankyouText>
					Thank you for submitting your request. We're reviewing your
					information and will notify you of our decision via mail within 5-7
					business days.
				</ThankyouText>
			)}
		</Wrapper>
	);
};

export default CliResults;
