import * as React from 'react';
import { getIn, FieldProps } from 'formik';
import { TextField } from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import TooltipHelper from './TooltipHelper';

export type FormikTextFieldProps = FieldProps &
	Partial<OutlinedTextFieldProps> & {
		disableHelperText?: boolean;
		options?: { label: string; value: any }[];
		tooltipContent?: string;
	};

export const FormikTextField: React.FunctionComponent<FormikTextFieldProps> = ({
	field,
	form,
	helperText,
	disableHelperText,
	options,
	tooltipContent,
	InputProps,
	...props
}) => {
	const error =
		getIn(form.touched, field.name) && getIn(form.errors, field.name);

	if (tooltipContent) {
		const endAdornment = (
			<TooltipHelper
				fieldName={field.name}
				tooltipContent={tooltipContent || ''}
			/>
		);
		if (InputProps) {
			InputProps.endAdornment = endAdornment;
		} else {
			InputProps = { endAdornment };
		}
	}
	return (
		<TextField
			fullWidth
			error={typeof error !== 'undefined'}
			helperText={disableHelperText ? null : error || helperText || ' '}
			margin="dense"
			variant="outlined"
			select={Boolean(options)}
			SelectProps={{
				native: true,
			}}
			InputProps={InputProps}
			{...field}
			{...props}
		>
			{options &&
				options.map((option, index: number) => {
					let optionElement = (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					);

					if (index === 0) {
						optionElement = (
							<React.Fragment key={option.value}>
								<option value={''} />
								{optionElement}
							</React.Fragment>
						);
					}

					return optionElement;
				})}
			}
		</TextField>
	);
};
