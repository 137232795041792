import React from 'react';
import styled from 'styled-components';
import Lock from '@material-ui/icons/Lock';

const HeaderWrapper = styled.header`
	height: 86px;
	display: flex;
`;

const SecureResponse = styled.div`
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	text-transform: uppercase;
	margin-left: 6px;
`;

const IconAndText = styled.div`
	color: #34495e;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	right: 24px;
	top: 36px;
`;

const Header: React.FC = () => {
	return (
		<HeaderWrapper>
			<IconAndText>
				<Lock />
				<SecureResponse>Secure Response</SecureResponse>
			</IconAndText>
		</HeaderWrapper>
	);
};

export default Header;
