import * as React from 'react';
import NumberFormat from 'react-number-format';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { FormikTextField, FormikTextFieldProps } from './FormikTextField';

const CurrencyInput: React.FunctionComponent<
	InputBaseComponentProps
> = function CurrencyInput(props) {
	const { inputRef, onChange, value, ...other } = props;

	return (
		<NumberFormat
			{...(other as any)}
			value={value}
			getInputRef={inputRef}
			onValueChange={values => {
				if (!onChange) return;
				onChange({
					target: {
						name: other.name,
						value: values.value,
					},
				} as any);
			}}
			decimalScale={2}
			fixedDecimalScale
			thousandSeparator
			prefix="$"
		/>
	);
};

const CurrencyTextField: React.FunctionComponent<
	FormikTextFieldProps
> = function CurrencyTextField(props) {
	return (
		<FormikTextField
			InputProps={{
				inputComponent: CurrencyInput,
			}}
			{...props}
			type="tel"
		/>
	);
};

export default CurrencyTextField;
export { CurrencyInput };
