import * as React from 'react';
import styled from 'styled-components';
import splashImage from '../images/splash-image_1600_whiteOverlay.jpg';

const SplashContainer = styled.div`
	background-image: url(${splashImage});
	background-size: cover;
	background-position: 100% 25%;
	width: 100%;
	height: 325px;
	margin-bottom: 36px;
`;

const ContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	margin: auto;
	max-width: 900px;
	padding: 0 1em;
`;

const Content = styled.div`
	width: 100%;
`;

const MainText = styled.div`
	max-width: 746px;
	margin: auto;
	${props => props.theme.typography.h4}
	width: 100%;

	@media screen and (min-width: 600px) {
		${props => props.theme.typography.h3}
	}
`;

const SubText = styled.div`
	margin-top: 24px;
	${props => props.theme.typography.subtitle1}
	width: 100%;

	@media screen and (min-width: 600px) {
		${props => props.theme.typography.h4}
	}
`;

const Superscript = styled.sup`
	${props => props.theme.typography.caption}
`;

const SplashImage = function() {
	return (
		<SplashContainer>
			<ContentWrapper>
				<Content>
					<MainText>
						Welcome. See if you qualify for a credit line increase.
					</MainText>
					<SubText>
						It's fast, easy, and doesn't impact your credit score.
						<Superscript>1</Superscript>
					</SubText>
				</Content>
			</ContentWrapper>
		</SplashContainer>
	);
};

export default SplashImage;
