import * as React from 'react';
import { CreditOfferApi } from '../Api';

const CreditOfferApiContext = React.createContext<CreditOfferApi>(null as any);

export const CreditOfferApiProvider = CreditOfferApiContext.Provider;

export const useCreditOfferApi = function useCreditOfferApi() {
	return React.useContext(CreditOfferApiContext);
};

export interface WithPortalApi {
	creditOfferApi: CreditOfferApi;
}

export const withCreditOfferApi = function withCreditOfferApi<
	T extends { creditOfferApi: CreditOfferApi }
>(Component: React.ComponentType<T>) {
	return class WithPortalApiDecorator extends React.Component<
		Omit<T, keyof WithPortalApi>
	> {
		static contextType = CreditOfferApiContext;

		render() {
			return <Component {...(this.props as T)} creditOfferApi={this.context} />;
		}
	};
};
