import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { supportedBrowsers } from './supportedBrowsers.js';
import { getHttpFileConfiguration } from './Configuration';
import { CreditOfferApi } from './Api';
import * as Sentry from '@sentry/browser';

const isDevelopment = process.env.NODE_ENV === 'development';

const sentryOptions: any = {
	dsn: 'https://f4f95ca55d204f5c87f5fab59ae14ccf@sentry.io/1732289',
};

if (!isDevelopment) Sentry.init(sentryOptions);

if (
	window.location.pathname !== '/browser-not-supported.html' &&
	!supportedBrowsers.test(navigator.userAgent)
) {
	window.location.replace('/browser-not-supported.html');
}

const documentRoot = document.getElementById('root');
function render(api?: CreditOfferApi) {
	ReactDOM.render(<App api={api || ({} as any)} />, documentRoot);
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

async function getConfigAndRender() {
	// TODO: Report CRA extra path
	const config = await getHttpFileConfiguration();

	if (config) {
		sentryOptions.environment = config.environment;
		if (!isDevelopment) Sentry.init(sentryOptions);
		render(new CreditOfferApi(config.apiUrl));
	}
}
getConfigAndRender();
