import Axios from 'axios';

export interface Configuration {
	apiUrl: string;
	environment: string;
}

/**
 * Pulls down a JSON configuration file from the root of the website to provide
 * the configuration interface
 */
export async function getHttpFileConfiguration(
	sitePrefix: string = '',
	domain?: string,
): Promise<Configuration | null> {
	let url = `${sitePrefix || ''}/config.json`;
	if (domain) {
		url = `${domain}${url}`;
	}

	const response = await Axios.get(url);
	if (response.data) {
		const { cliApiUrl, ...others } = response.data;
		return { apiUrl: cliApiUrl, ...others };
	}
	return null;
}
