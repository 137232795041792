import axios, { AxiosInstance } from 'axios';
import { CodeFormData } from './components/CodeForm';
import { CliFormData } from './components/CliForm';

export interface VerifyCodeResponse {
	valid: boolean;
	campaignStatus: 'suspended' | 'active' | 'expired';
	firstName: string;
	lastName: string;
	currentCreditLine: number;
	accountNumberLastFour: number;
}

export interface CliResponse {
	valid: boolean;
	newCreditLine: number;
}

export class CreditOfferApi {
	private request: AxiosInstance;

	constructor(url: string) {
		this.request = axios.create({
			baseURL: `${url}/rest/s1/creditLineIncrease`,
		});
	}

	submitApplication = async (data: CliFormData) => {
		try {
			const response = await this.request.post(
				`${this.request.defaults.baseURL}/submit-application`,
				data,
			);

			if (response.status === 200) {
				return response.data as CliResponse;
			}
		} catch (err) {
			console.error(err);
			throw err;
		}
	};

	verifyCode = async (data: CodeFormData) => {
		try {
			const response = await this.request.post(
				`${this.request.defaults.baseURL}/verify-code`,
				data,
			);

			if (response.status === 200) {
				return response.data as VerifyCodeResponse;
			}
		} catch (err) {
			console.error(err);
			throw err;
		}
	};
}
