import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Typography } from '@material-ui/core';

class TopLevelErrorBoundary extends React.Component {
	state = {
		hasError: false,
		eventId: undefined,
	};

	static getDerivedStateFromError(error: Error) {
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		console.error('App failed to render: ', error, info);
		Sentry.withScope(scope => {
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	refreshPage() {
		window.location.reload();
	}

	render() {
		if (this.state.hasError) {
			return (
				<div style={{ margin: '24px', textAlign: 'center' }}>
					<Typography variant="h4">There was a problem...</Typography>
					<Typography variant="body1">
						Go back to the{' '}
						<a href="/" onClick={this.refreshPage}>
							Homepage
						</a>
					</Typography>
				</div>
			);
		}

		return this.props.children;
	}
}

export default TopLevelErrorBoundary;
