import * as React from 'react';
import styled from 'styled-components';
import { Form as FormikForm, FastField, Formik } from 'formik';
import { FormikTextField } from './Field/FormikTextField';
import { object, string } from 'yup';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import CliForm from './CliForm';
import { useCreditOfferApi } from './CreditOfferApiProvider';
import SplashImage from './SplashImage';
import FootnoteFaq from './FootnoteFaq';

const Wrapper = styled.div`
	height: 100%;
	text-align: center;
	position: relative;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
`;

const Form = styled(FormikForm as any)`
	max-width: 684px;
	margin: 0 auto;
	width: 100%;
`;

const InputContainer = styled(Grid as any)`
	padding: 0 1em;
`;

export const SubmitButton = styled(Button as any)`
	max-width: 275px;
	width: 100%;

	&& {
		box-shadow: none;
		text-transform: none;
		margin: 1em 0;
	}
`;

export const ButtonWrapper = styled.div`
	position: relative;
`;

export const SubmitProgress = styled(CircularProgress)`
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
`;

const ErrorText = styled.div`
	font-size: 0.875rem;
	color: ${props => props.theme.palette.error.main};
	margin-top: 12px;
`;

const ExpiredText = styled.div`
	${props => props.theme.typography.body1};
	margin: auto;
	max-width: 40rem;
	padding: 1em;
`;

export interface CodeFormData {
	invitationCode: string;
}

export function getInitialValues(): CodeFormData {
	return {
		invitationCode: '',
	};
}

export function getValidation() {
	return object<CodeFormData>({
		invitationCode: string().required('Please enter the invitation code.'),
	});
}

const CodeForm: React.FunctionComponent<{}> = function CodeForm(props) {
	const [loading, setLoading] = React.useState(false);
	const [codeError, setCodeError] = React.useState<undefined | Boolean>(
		undefined,
	);
	const [cliData, setCliData] = React.useState({} as any);
	const api = useCreditOfferApi();

	function setError() {
		setCodeError(true);
		setLoading(false);
	}

	const onSubmit = async (data: CodeFormData) => {
		setCodeError(undefined);
		setLoading(true);

		const response = await api.verifyCode(data);

		if (response) {
			setCodeError(false);

			if (response.valid) {
				setCliData({
					invitationCode: data.invitationCode,
					firstName: response.firstName,
					lastName: response.lastName,
					currentCreditLine: response.currentCreditLine,
					accountNumberLastFour: response.accountNumberLastFour,
				});
			} else if (response.campaignStatus === 'expired') {
				setCliData({
					isExpired: true,
				});
			} else {
				setError();
			}
		} else {
			setError();
		}
	};

	return (
		<Wrapper>
			{codeError !== false ? (
				<React.Fragment>
					<SplashImage />

					<Formik
						onSubmit={onSubmit}
						initialValues={{ ...getInitialValues() }}
						validationSchema={getValidation()}
						render={() => (
							<Form>
								<InputContainer container>
									<Grid item xs={12}>
										<FastField
											component={FormikTextField}
											name="invitationCode"
											label="Invitation Code"
										/>
									</Grid>
								</InputContainer>

								<ButtonWrapper>
									<SubmitButton
										color="primary"
										variant="contained"
										type="submit"
										disabled={loading}
									>
										Submit Code
									</SubmitButton>
									{loading && <SubmitProgress size={24} />}
								</ButtonWrapper>

								{codeError && (
									<ErrorText>
										The code entered is invalid. Please try again.
										<br />
										For help, please call 1-800-883-0131.
									</ErrorText>
								)}
							</Form>
						)}
					/>

					<FootnoteFaq />
				</React.Fragment>
			) : cliData.isExpired ? (
				<ExpiredText>
					Thank you for your interest in the credit line increase offer,
					however, this promotion has expired and is no longer available.
				</ExpiredText>
			) : (
				<CliForm cliData={cliData} />
			)}
		</Wrapper>
	);
};

export default CodeForm;
