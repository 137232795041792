import { InputProps } from '@material-ui/core/Input';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { FieldProps } from 'formik';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { FormikTextField } from './FormikTextField';

const maskPattern = [
	'(',
	/\d/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

const PhoneNumberMaskedInput = function PhoneNumberMaskedInput({
	inputRef,
	...other
}: InputProps) {
	return (
		<MaskedInput
			{...(other as any)}
			ref={ref => {
				(inputRef as any)(ref ? ref.inputElement : null);
			}}
			mask={maskPattern}
			placeholderChar={'\u2000'}
			guide={false}
		/>
	);
};

const PhoneNumberNumberField: React.FunctionComponent<
	FieldProps & Partial<OutlinedTextFieldProps>
> = function PhoneNumberNumberField(props) {
	return (
		<FormikTextField
			{...props}
			InputProps={{
				inputComponent: PhoneNumberMaskedInput,
			}}
			type="tel"
		/>
	);
};

export default PhoneNumberNumberField;
