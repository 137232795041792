import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export const outlinedInputSidePadding = 30;

const htmlFontSize = 16;
const fontSize = 16;

const pxToRem = function pxToRem(size: number) {
	return `${(size / htmlFontSize) * (fontSize / htmlFontSize)}rem`;
};

const fontFamily = '"Open Sans", Roboto, "Helvetica Neue", Arial, sans-serif';

const theme = createMuiTheme({
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 21,
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: '1rem',
				'&$focused': {
					color: 'hsl(206.6,89.7%,54.1%)',
				},
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: 8,
				marginBottom: 4,
			},
		},
		MuiInputLabel: {
			formControl: {
				top: 4,
			},
			outlined: {
				transform: `translate(${outlinedInputSidePadding}px, 20px) scale(1)`,
				'&$marginDense': {
					transform: `translate(${outlinedInputSidePadding}px, 11px) scale(1)`,
				},
				'&$shrink': {
					transform: `translate(${outlinedInputSidePadding}px, -10px) scale(0.75)`,
				},
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: `0 ${outlinedInputSidePadding}px`,
				borderRadius: '30px',
			},
			notchedOutline: {
				paddingLeft: '26px !important',
				borderRadius: '30px',
			},
			adornedEnd: {
				paddingRight: 0,
			},
			inputMarginDense: {
				paddingTop: 14.5,
				paddingBottom: 14.5,
			},
		},
		MuiSelect: {
			select: {
				borderRadius: '30px',
				'&:focus': {
					borderRadius: '30px',
				},
			},
			selectMenu: {
				borderRadius: '30px',
				'&:focus': {
					borderRadius: '30px',
				},
			},
			icon: {
				right: `${outlinedInputSidePadding * 0.5}px`,
			},
			outlined: {
				width: 'calc(100% - 63px)',
			},
		},
		MuiTypography: {
			gutterBottom: {
				marginBottom: '0.5em',
			},
		},
	},
	palette: {
		primary: {
			main: blue[500],
		},
	},
	typography: {
		fontSize,
		htmlFontSize,
		pxToRem,
		useNextVariants: true,
		fontFamily: [
			'"Open Sans"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		h1: {
			fontWeight: 300,
			fontSize: pxToRem(96),
			lineHeight: '131px',
			letterSpacing: '-1.5px',
		},
		// app title
		h2: {
			fontWeight: 300,
			fontSize: pxToRem(60),
			lineHeight: '82px',
			letterSpacing: '-0.5px',
		},
		// not assigned
		h3: {
			fontWeight: 600,
			fontSize: pxToRem(48),
			lineHeight: '65px',
			letterSpacing: '0',
		},
		// component title
		h4: {
			fontWeight: 400,
			fontSize: pxToRem(34),
			lineHeight: '46px',
			letterSpacing: '0.25px',
		},
		// window title
		h5: {
			fontWeight: 600,
			fontSize: pxToRem(24),
			lineHeight: '33px',
			letterSpacing: '0',
		},
		// menu title
		h6: {
			fontWeight: 600,
			fontSize: pxToRem(20),
			lineHeight: '27px',
			letterSpacing: '-0.5px',
		},
		subtitle1: {
			fontWeight: 400,
			fontSize: pxToRem(16),
			lineHeight: '20px',
			letterSpacing: '0.15px',
		},
		subtitle2: {
			fontWeight: 600,
			fontSize: pxToRem(16),
			lineHeight: '16px',
			letterSpacing: '0',
		},
		body1: {
			fontWeight: 400,
			fontSize: pxToRem(18),
			lineHeight: '28px',
			letterSpacing: '0.5px',
		},
		body2: {
			fontWeight: 400,
			fontSize: pxToRem(14),
			lineHeight: '20px',
			letterSpacing: '0.1px',
		},
		button: {
			fontWeight: 600,
			fontSize: pxToRem(14),
			lineHeight: 1.75,
			letterSpacing: '0',
			textTransform: 'none',
		},
		caption: {
			fontWeight: 400,
			fontSize: pxToRem(12),
			lineHeight: '16px',
			letterSpacing: '0',
		},
		overline: {
			fontWeight: 600,
			fontSize: pxToRem(10),
			lineHeight: '16px',
			letterSpacing: '0.5px',
			textTransform: 'uppercase',
		},
		subheading: {
			fontFamily,
			fontSize: pxToRem(16),
			fontWeight: 400,
		},
	} as any,
});

const styledTheme = {
	appBarBackgroundColor: 'hsl(0,0%,100%)',
	appBarTextColor: 'hsla(0, 0%, 0%, 0.87)',
};

export default { ...styledTheme, ...createMuiTheme(theme) };
