import * as React from 'react';
import HelpOutline from '@material-ui/icons/HelpOutline';
import {
	ClickAwayListener,
	IconButton,
	InputAdornment,
	Tooltip,
	withStyles,
	WithStyles,
} from '@material-ui/core';

const styles: Record<string, any> = {
	tooltip: {
		backgroundColor: 'hsla(0, 0%, 0%, 0.9)',
		textAlign: 'left',
		fontSize: '0.75rem',
		padding: '8px 12px',
	},
};

interface TooltipHelperProps extends WithStyles<typeof styles> {
	fieldName: string;
	tooltipContent: string;
}

const TooltipHelper: React.FunctionComponent<TooltipHelperProps> = ({
	classes,
	fieldName,
	tooltipContent,
}) => {
	const [open, setOpen] = React.useState(false);

	function handleTooltipClose() {
		setOpen(false);
	}

	function handleTooltipOpen() {
		setOpen(true);
	}

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					classes={{ tooltip: classes.tooltip }}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					onClose={handleTooltipClose}
					open={open}
					placement="bottom-end"
					title={tooltipContent}
					children={
						<InputAdornment position="end">
							<IconButton
								onClick={handleTooltipOpen}
								role="tooltip"
								aria-labelledby={fieldName}
							>
								<HelpOutline />
							</IconButton>
						</InputAdornment>
					}
				/>
			</div>
		</ClickAwayListener>
	);
};

export default withStyles(styles)(TooltipHelper);
