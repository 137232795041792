import React from 'react';
import './App.css';
import CodeForm from './components/CodeForm';
import Header from './components/Header';
import Footer from './components/Footer';
import styled, { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import { CreditOfferApi } from './Api';
import { CreditOfferApiProvider } from './components/CreditOfferApiProvider';
import TopLevelErrorBoundary from './components/TopLevelErrorBoundary';

const FlexWrapper = styled.div`
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
`;

const App: React.FC<{ api: CreditOfferApi }> = ({ api }) => {
	return (
		<TopLevelErrorBoundary>
			<MuiThemeProvider theme={theme}>
				<ThemeProvider theme={theme}>
					<CreditOfferApiProvider value={api}>
						<Header />
						<FlexWrapper>
							<CodeForm />
						</FlexWrapper>
						<Footer />
					</CreditOfferApiProvider>
				</ThemeProvider>
			</MuiThemeProvider>
		</TopLevelErrorBoundary>
	);
};

export default App;
