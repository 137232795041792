import * as React from 'react';
import styled from 'styled-components';
import { Form as FormikForm, FastField, Formik, FormikProps } from 'formik';
import { FormikTextField } from './Field/FormikTextField';
import { object, string, number } from 'yup';
import { EmailValidator } from 'commons-validator-js';
import { Grid, Typography, Dialog, Button } from '@material-ui/core';
import CurrencyField from './Field/CurrencyField';
import PhoneNumberField from './Field/PhoneNumberField';
import CliResults from './CliResults';
import { SubmitProgress, ButtonWrapper, SubmitButton } from './CodeForm';
import {
	stripNonDigits,
	checkForRepetition,
	formatCurrency,
} from '../util/util';
import { useCreditOfferApi } from './CreditOfferApiProvider';
import SplashImage from './SplashImage';
import FootnoteFaq from './FootnoteFaq';

const Wrapper = styled.div`
	height: 100%;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
`;

const Form = styled(FormikForm as any)`
	max-width: 684px;
	margin: 0 auto 1em auto;
`;

const InputContainer = styled(Grid as any)`
	padding: 0 1em;
`;

const SectionHeading = styled(Typography as any)`
	text-align: left;
	width: 100%;
`;

const CurrentCreditLine = styled(Typography as any)`
	&& {
		text-align: left;
		margin-bottom: 1em;
		width: 100%;
	}
`;

const DialogContent = styled.div`
	padding: 24px;
	text-align: center;

	& h6 {
		margin-bottom: 12px;
	}
`;

const DialogButtons = styled.div`
	display: flex;
	justify-content: space-between;

	& button {
		box-shadow: none;
		min-width: 100px;
	}
`;

const Footnote = styled(Typography as any)`
	&& {
		display: block;
		margin-top: 16px;
		text-align: left;
		width: 100%;
	}
`;

const FaqFootnote = styled(FootnoteFaq as any)`
	flex-grow: 1;
`;

export interface CliFormData {
	invitationCode: string;
	emailAddress: string;
	phoneNumber: string;
	residenceStatusEnumId: string;
	housingPayment: string | number;
	annualIncome: string | number;
}

export function getInitialValues(): CliFormData {
	return {
		invitationCode: '',
		emailAddress: '',
		phoneNumber: '',
		residenceStatusEnumId: '',
		housingPayment: '',
		annualIncome: '',
	};
}

function housingRequiresPayment(residenceStatusEnumId: string) {
	return (
		residenceStatusEnumId === 'RessPrivateTenant' ||
		residenceStatusEnumId === 'RessMortgage'
	);
}

export function getValidation() {
	const emailValidator = new EmailValidator();
	return object<CliFormData>({
		invitationCode: string(),
		emailAddress: string()
			.test('is-valid', 'Please enter a valid email address', value =>
				value ? emailValidator.isValid(value) : true,
			)
			.required('Please enter your email address.'),
		phoneNumber: string()
			.matches(
				/([(])\d{3}([)])([ ])\d{3}([-])\d{4}/,
				'Please enter a valid phone number.',
			)
			.test(
				'isNotRepetitive',
				'Please enter a valid phone number.',
				testValue => checkForRepetition(testValue, 10),
			)
			.required('Please enter your phone number.'),
		residenceStatusEnumId: string().required('Please enter your housing type.'),
		housingPayment: number()
			.typeError('Please enter a valid amount.')
			.required('Please enter your housing payment monthly.')
			.min(0, 'Please enter a valid amount.')
			.test('greaterThan0', 'Please enter an amount greater than 0', function(
				payment,
			) {
				if (
					payment !== '' &&
					housingRequiresPayment(this.parent.residenceStatusEnumId)
				) {
					return payment > 0;
				}

				return true;
			}),
		annualIncome: number()
			.min(0, 'Please enter a valid amount.')
			.required('Please enter your pre-tax income annual.'),
	});
}

interface CliFormProps {
	cliData: {
		invitationCode: string;
		firstName: string;
		lastName: string;
		currentCreditLine: number;
		accountNumberLastFour: number;
	};
	style?: any;
}

const CliFormikForm: React.FunctionComponent<
	{
		cliData: CliFormProps['cliData'];
		loading?: boolean;
		onDialogClose: () => void;
		openDialog: boolean;
	} & FormikProps<CliFormData>
> = function CliFormikForm(props) {
	const {
		loading,
		onDialogClose,
		openDialog,
		setFieldTouched,
		setFieldValue,
		submitForm,
		values,
	} = props;
	const residenceStatusEnumId = values.residenceStatusEnumId;
	const housingPayment = values.housingPayment;

	const paymentDisabled =
		residenceStatusEnumId !== '' &&
		!housingRequiresPayment(residenceStatusEnumId);

	React.useEffect(() => {
		if (paymentDisabled) {
			setFieldValue('housingPayment', '0.00');
		}

		if (housingPayment) {
			setFieldTouched('housingPayment', true);
		}
	}, [paymentDisabled, housingPayment, setFieldTouched, setFieldValue]);

	return (
		<Form>
			<InputContainer container>
				<SectionHeading variant="h6">
					{`Welcome ${props.cliData.firstName} ${props.cliData.lastName}`}
				</SectionHeading>

				<CurrentCreditLine variant="body1">
					{props.cliData.accountNumberLastFour && (
						<>
							{`Account Number: XXXXXXXXX${props.cliData.accountNumberLastFour}`}
							<br />
						</>
					)}
					{`Current Credit Line: ${formatCurrency(
						props.cliData.currentCreditLine,
					)}`}
				</CurrentCreditLine>
				<Grid item xs={12}>
					<FastField
						autoComplete="email"
						component={FormikTextField}
						name="emailAddress"
						label="Email Address"
					/>
					<FastField
						autoComplete="tel"
						component={PhoneNumberField}
						name="phoneNumber"
						label="Mobile Phone Number"
					/>
				</Grid>
				<SectionHeading variant="h6">Housing</SectionHeading>
				<Grid item xs={12}>
					<FastField
						component={FormikTextField}
						name="residenceStatusEnumId"
						label="Housing Type"
						options={[
							{
								label: 'Rent',
								value: 'RessPrivateTenant',
							},
							{
								label: 'Own with debt',
								value: 'RessMortgage',
							},
							{
								label: 'Own without debt',
								value: 'RessOwn',
							},
							{
								label: 'Other without payment',
								value: 'RessOther',
							},
						]}
					/>
					<FastField
						component={CurrencyField}
						disabled={paymentDisabled}
						name="housingPayment"
						label="Housing Payment Monthly"
						tooltipContent="If you share a housing payment, enter the amount you are personally responsible for."
					/>
				</Grid>
				<SectionHeading variant="h6">Annual Income</SectionHeading>
				<Grid item xs={12}>
					<FastField
						component={CurrencyField}
						name="annualIncome"
						label="Pre-Tax Income Annual"
						helperText="Per year (e.g. 50,000). Alimony, child support, or separate maintenance income need not be provided if you do not wish it to be considered as a basis for repaying this obligation."
						tooltipContent="You may include personal income, which is income you have earned including retirement income, dividends, interest, rents, annuities, etc."
					/>
				</Grid>

				<Footnote variant="caption">
					Please note we will not obtain a credit report for this credit line
					increase request.
				</Footnote>
			</InputContainer>

			<Dialog open={openDialog} onClose={onDialogClose}>
				<DialogContent>
					<Typography variant="subtitle1">
						Please confirm this is your pre-tax annual income:
					</Typography>
					<Typography variant="subtitle2">
						{formatCurrency(values.annualIncome as number)}
					</Typography>
					<DialogButtons>
						<Button color="primary" onClick={onDialogClose}>
							Back
						</Button>
						<Button color="primary" variant="contained" onClick={submitForm}>
							Confirm
						</Button>
					</DialogButtons>
				</DialogContent>
			</Dialog>

			<ButtonWrapper>
				<SubmitButton
					color="primary"
					variant="contained"
					type="submit"
					disabled={loading}
				>
					Submit
				</SubmitButton>
				{loading && <SubmitProgress size={24} />}
			</ButtonWrapper>
		</Form>
	);
};

const CliForm: React.FunctionComponent<CliFormProps> = function CliForm(props) {
	const [newCreditLine, setNewCreditLine] = React.useState<
		undefined | boolean | number
	>(undefined);
	const [openDialog, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const api = useCreditOfferApi();

	const onSubmit = async (data: CliFormData) => {
		if (!openDialog && data.annualIncome <= 10000) {
			setOpen(true);

			return;
		}

		setOpen(false);
		setLoading(true);

		data.invitationCode = props.cliData.invitationCode;
		data.phoneNumber = stripNonDigits(data.phoneNumber);
		data.housingPayment = parseFloat(data.housingPayment as string);
		data.annualIncome = parseFloat(data.annualIncome as string);

		const response = await api.submitApplication(data);

		if (
			response &&
			response.valid &&
			typeof response.newCreditLine === 'number'
		) {
			setNewCreditLine(response.newCreditLine);
		} else {
			setNewCreditLine(false);
		}
	};

	const showForm = typeof newCreditLine === 'undefined';
	return (
		<Wrapper>
			{showForm ? (
				<>
					<SplashImage />
					<Formik
						initialValues={{ ...getInitialValues() }}
						onSubmit={onSubmit}
						validationSchema={getValidation()}
						render={(formikProps: FormikProps<CliFormData>) => (
							<CliFormikForm
								{...formikProps}
								loading={loading}
								cliData={props.cliData}
								openDialog={openDialog}
								onDialogClose={() => setOpen(false)}
							/>
						)}
					/>

					<FaqFootnote />
				</>
			) : (
				<CliResults newCreditLine={newCreditLine} />
			)}
		</Wrapper>
	);
};

export default CliForm;
