export function checkForRepetition(value: string, length: number) {
	const cleanValue = stripNonDigits(value);

	if (cleanValue && cleanValue.length !== length) {
		return true;
	}

	const repetitionRegex = new RegExp('(.)\\1{' + (length - 1) + ',}');

	return !repetitionRegex.test(cleanValue);
}

function currencyFormatterShim(x: number) {
	return '$' + x;
}

const currencyFormatter =
	typeof Intl !== 'undefined'
		? new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
		  })
		: {
				format: currencyFormatterShim,
		  };

const currencyFormatterNoFraction =
	typeof Intl !== 'undefined'
		? new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
		  })
		: {
				format: currencyFormatterShim,
		  };

export function formatCurrency(
	currencyValue: number,
	options?: { noFraction?: boolean },
) {
	if (!currencyValue) {
		return;
	}

	if (options && options.noFraction === true) {
		return currencyFormatterNoFraction.format(currencyValue);
	} else {
		return currencyFormatter.format(currencyValue);
	}
}

export function stripNonDigits(digitString: string = '') {
	return digitString.replace(/[^0-9]/g, '');
}
