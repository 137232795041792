import * as React from 'react';
import styled from 'styled-components';
import {
	Button,
	Dialog,
	DialogTitle,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	IconButton,
	Typography,
	WithStyles,
	withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles: Record<string, any> = {
	paper: {
		height: '100%',
	},
	expanded: {
		'&$expanded': {
			margin: '2px 0'
		}
	}
};

const FootNote = styled.div`
	margin: auto auto 1em auto;
	text-align: justify;
	width: 100%;
`;

const FootNoteContent = styled.div`
	margin: 1em auto 0 auto;
	max-width: 684px;
	padding: 0 1em;
`;

const FaqWrapper = styled(Typography as any)`
	display: flex;
`;

const FaqButton = styled(Button as any)`
	&& {
		padding: 0;
		margin-left: 6px;

		&:hover {
			background-color: white;
			text-decoration: underline;
		}
	}
`;

const FaqDialogTitle = styled(DialogTitle as any)`
	&& {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& button {
			margin-right: -12px;
		}
	}
`;

const FootnoteFaq: React.SFC<WithStyles> = function({ classes }) {
	const [open, setOpen] = React.useState(false);

	function closeDialog() {
		setOpen(false);
	}

	return (
		<>
			<FootNote>
				<FootNoteContent>
					<FaqWrapper variant="body1">
						Questions?
						<FaqButton color="primary" onClick={() => setOpen(true)}>
							<Typography variant="body1">Check out our FAQ</Typography>
						</FaqButton>
					</FaqWrapper>
					<Typography variant="caption">
						<sup>1</sup> A new credit report will not be pulled for this
						promotional offer. We are using debt information obtained during our
						standard account review process to make our decision.
					</Typography>
				</FootNoteContent>
			</FootNote>

			<Dialog
				classes={{
					paper: classes.paper,
				}}
				maxWidth="md"
				open={open}
				onClose={closeDialog}
			>
				<FaqDialogTitle disableTypography={true}>
					<Typography variant="h6">Frequently Asked Questions</Typography>
					<IconButton onClick={closeDialog}>
						<CloseIcon />
					</IconButton>
				</FaqDialogTitle>
				<ExpansionPanel classes={{ expanded: classes.expanded }} defaultExpanded elevation={0}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="subtitle2">
							Will you obtain a new credit report for this promotional credit
							line increase offer?
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography variant="subtitle1">
							We will not obtain a credit report. We will make our decision on
							our existing credit information and your pre-tax annual income.
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel classes={{ expanded: classes.expanded }} defaultExpanded elevation={0}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="subtitle2">
							When will I know if my request has been approved?
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography variant="subtitle1">
							Once you submit your information, you will be informed of the
							decision right away.
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel classes={{ expanded: classes.expanded }} defaultExpanded elevation={0}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="subtitle2">
							Why does my pre-tax annual income need to be provided?
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography variant="subtitle1">
							We need your income information to evaluate your ability to repay.
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel classes={{ expanded: classes.expanded }} defaultExpanded elevation={0}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="subtitle2">
							Is there a fee associated with my request for a credit line
							increase?
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography variant="subtitle1">
							There is no fee associated with a credit line increase request.
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Dialog>
		</>
	);
};

export default withStyles(styles)(FootnoteFaq);
